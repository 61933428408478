// Custom JS

    // Login AJAX
    $(document).on('submit', '.logon', function(e) {

        e.preventDefault();
        
        $("#logon").prop("disabled", true);

        var $form   = $(this);
        var data    = $form.serialize();

        $.ajax({
            method:     'POST',
            url:        'commons/globals.php',
            data:       data,
            headers:    {'Content-Type':'application/x-www-form-urlencoded'}
        })
        .success(function(response) {
            if(response == "ok"){
                $(".modal-footer").append('<p class="pull-left"><i class="fa fa-repeat fa-spin-custom"></i>&nbsp;<strong>Signing In...</strong></p>');
                setTimeout('window.location.href = "/admin";',2000);
            } else {
                $("#error").fadeIn(1000, function(){      
                    $("#error").html('<div class="alert alert-danger"><i class="fa fa-warning"></i>&nbsp;&nbsp;'+response+' !</div>');
                });
                $("#logon").prop("disabled", false);
            }
        });

        return false;

    });

    // Register AJAX
    $(document).on('submit', '#registerForm', function(e) {

        e.preventDefault();
        $('#dvLoading, #overlay, #overlay-back').fadeIn(500);

        $(".registerCompany").prop("disabled", true);

        var $form   = $(this);
        var data    = $(this).serialize();

        $.ajax({
            method:     'POST',
            url:        'commons/register.php',
            data:       data,
            headers:    {'Content-Type':'application/x-www-form-urlencoded'}
        })
        .success(function(response) {
            
            if(response == "ok"){
                $('#dvLoading, #overlay, #overlay-back').fadeOut();
                $(".alertbox").fadeIn(1000, function(){      
                    $(".alertbox").html('<div class="alert alert-success"><i class="fa fa-check"></i>&nbsp;&nbsp; You\'re registration has been successfully processed and will dealt with in due course, please allow upto 24 hours for processing this application!</div>');
                });
                $("#registerForm")[0].reset();
            } else {
                $('#dvLoading, #overlay, #overlay-back').fadeOut();
                $(".alertbox").fadeIn(1000, function(){      
                    $(".alertbox").html('<div class="alert alert-danger"><i class="fa fa-warning"></i>&nbsp;&nbsp;'+response+' !</div>');
                });
                $(".registerCompany").prop("disabled", false);
            }

        });

        return false; // avoid to execute the actual submit of the form.
    
    });

    // Resize DIV
    $(document).ready(function(){
      resizeDiv();
    });

    window.onresize = function(event) {
      resizeDiv();
    }

    function resizeDiv() {
      vpw = $(window).width();
      vph = $(window).height();
      $('#intro').css({'height': vph + 'px'});
    }

	$(document).ready(function() {

        // Local Authority Cloning Script
        $("#AdminContent").on('click', '#addAuthority', function(){
            var clone       = $(".authority-group:first").clone();
            var cloneCount  = clone.length + 1;
            clone.find("input").val("");
            // clone.find("label").append(" #"+cloneCount);
            clone.find("#controls").replaceWith('<div class="col-md-2 col-sm-2" id="controls" style="display:inline-block;"><button type="button" class="btn btn-success addButton" id="addAuthority"><i class="fa fa-plus"></i></button>&nbsp&nbsp;<button type="button" class="btn btn-danger removeButton"><i class="fa fa-minus"></i></button></div>');
            $(".authority-group:first").before(clone);
        });

        $("#AdminContent").on('click', '.removeButton', function(){
            if ($(".authority-group").length != 1) {
                $(".authority-group:first").remove();
            }
            event.preventDefault();
        });

        // Form Calculations
        $("#AdminContent").on('blur', 'input[name^=inputNetCost]', function() {
            var NetCost = $(this).val();
            // $('input[name^=netcostVAT]').val(Math.round(NetCost*1.20).toFixed(2));
            $('input[name^=netcostVAT]').val(Math.round(NetCost*1.20).toFixed(2));
        });

        $("#AdminContent").on('blur', 'input[name^=disbursement]', function() {
            var Disbursement = $(this).val();
            $('input[name^=disbursmentVAT]').val(Math.round(Disbursement*1.20).toFixed(2));
        });

        // Intialise Rejected Form
        $("#pendingOrders").one('click','#reject', function() {
            var pendingID = $(this).parents().closest('tr').attr('id');
            var counter   = $('#pendingOrders tr').length - 1;
            var newRow    = $('<tr>');
            var cols      = "";
            cols += '<td colspan="6">';
            cols += '   <table width="100%">';
            cols += '   <tr><td>';
            cols += '       <form name="reject_orders" method="POST" action="/admin/index.php">';
            cols += '       <input type="hidden" name="order_id" value="'+ pendingID +'">';
            cols += '       <textarea name="rejectReason" class="form-control" rows="3" placeholder="Please state reason for rejection of this order"></textarea>';
            cols += '       <button name="rejectOrder" class="btn btn-warning btn-sm pull-right" style="margin:10px 0 5px;">&nbsp;Submit&nbsp;&nbsp;Rejection&nbsp;</button>';
            cols += '       </form>';
            cols += '   </td></tr>';
            cols += '   </table>';
            cols += '</td>';
            newRow.append(cols);
            newRow.insertAfter($(this).parents().closest('tr'));
            event.preventDefault();
        });

    });

var fakewaffle = ( function ( $, fakewaffle ) {
    'use strict';

    fakewaffle.responsiveTabs = function ( collapseDisplayed ) {

        fakewaffle.currentPosition = 'tabs';

        var tabGroups = $( '.nav-tabs.responsive' );
        var hidden    = '';
        var visible   = '';
        var activeTab = '';

        if ( collapseDisplayed === undefined ) {
            collapseDisplayed = [ 'xs', 'sm' ];
        }

        $.each( collapseDisplayed, function () {
            hidden  += ' hidden-' + this;
            visible += ' visible-' + this;
        } );

        $.each( tabGroups, function ( index ) {
            var collapseDiv;
            var $tabGroup = $( this );
            var tabs      = $tabGroup.find( 'li a' );

            if ( $tabGroup.attr( 'id' ) === undefined ) {
                $tabGroup.attr( 'id', 'tabs-' + index );
            }

            collapseDiv = $( '<div></div>', {
                'class' : 'panel-group responsive' + visible,
                'id'    : 'collapse-' + $tabGroup.attr( 'id' )
            } );

            $.each( tabs, function () {
                var $this          = $( this );
                var oldLinkClass   = $this.attr( 'class' ) === undefined ? '' : $this.attr( 'class' );
                var newLinkClass   = 'accordion-toggle';
                var oldParentClass = $this.parent().attr( 'class' ) === undefined ? '' : $this.parent().attr( 'class' );
                var newParentClass = 'panel panel-default';
                var newHash        = $this.get( 0 ).hash.replace( '#', 'collapse-' );

                if ( oldLinkClass.length > 0 ) {
                    newLinkClass += ' ' + oldLinkClass;
                }

                if ( oldParentClass.length > 0 ) {
                    oldParentClass = oldParentClass.replace( /\bactive\b/g, '' );
                    newParentClass += ' ' + oldParentClass;
                    newParentClass = newParentClass.replace( /\s{2,}/g, ' ' );
                    newParentClass = newParentClass.replace( /^\s+|\s+$/g, '' );
                }

                if ( $this.parent().hasClass( 'active' ) ) {
                    activeTab = '#' + newHash;
                }

                collapseDiv.append(
                    $( '<div>' ).attr( 'class', newParentClass ).html(
                        $( '<div>' ).attr( 'class', 'panel-heading' ).html(
                            $( '<h4>' ).attr( 'class', 'panel-title' ).html(
                                $( '<a>', {
                                    'class'       : newLinkClass,
                                    'data-toggle' : 'collapse',
                                    'data-parent' : '#collapse-' + $tabGroup.attr( 'id' ),
                                    'href'        : '#' + newHash,
                                    'html'        : $this.html()
                                } )
                            )
                        )
                    ).append(
                        $( '<div>', {
                            'id'    : newHash,
                            'class' : 'panel-collapse collapse'
                        } )
                    )
                );
            } );

            $tabGroup.next().after( collapseDiv );
            $tabGroup.addClass( hidden );
            $( '.tab-content.responsive' ).addClass( hidden );

            if ( activeTab ) {
                $( activeTab ).collapse( 'show' );
            }
        } );

        fakewaffle.checkResize();
        fakewaffle.bindTabToCollapse();
    };

    fakewaffle.checkResize = function () {

        if ( $( '.panel-group.responsive' ).is( ':visible' ) === true && fakewaffle.currentPosition === 'tabs' ) {
            fakewaffle.tabToPanel();
            fakewaffle.currentPosition = 'panel';
        } else if ( $( '.panel-group.responsive' ).is( ':visible' ) === false && fakewaffle.currentPosition === 'panel' ) {
            fakewaffle.panelToTab();
            fakewaffle.currentPosition = 'tabs';
        }

    };

    fakewaffle.tabToPanel = function () {

        var tabGroups = $( '.nav-tabs.responsive' );

        $.each( tabGroups, function ( index, tabGroup ) {

            // Find the tab
            var tabContents = $( tabGroup ).next( '.tab-content' ).find( '.tab-pane' );

            $.each( tabContents, function ( index, tabContent ) {
                // Find the id to move the element to
                var destinationId = $( tabContent ).attr( 'id' ).replace ( /^/, '#collapse-' );

                // Convert tab to panel and move to destination
                $( tabContent )
                    .removeClass( 'tab-pane' )
                    .addClass( 'panel-body fw-previous-tab-pane' )
                    .appendTo( $( destinationId ) );

            } );

        } );

    };

    fakewaffle.panelToTab = function () {

        var panelGroups = $( '.panel-group.responsive' );

        $.each( panelGroups, function ( index, panelGroup ) {

            var destinationId = $( panelGroup ).attr( 'id' ).replace( 'collapse-', '#' );
            var destination   = $( destinationId ).next( '.tab-content' )[ 0 ];

            // Find the panel contents
            var panelContents = $( panelGroup ).find( '.panel-body.fw-previous-tab-pane' );

            // Convert to tab and move to destination
            panelContents
                .removeClass( 'panel-body fw-previous-tab-pane' )
                .addClass( 'tab-pane' )
                .appendTo( $( destination ) );

        } );

    };

    fakewaffle.bindTabToCollapse = function () {

        var tabs     = $( '.nav-tabs.responsive' ).find( 'li a' );
        var collapse = $( '.panel-group.responsive' ).find( '.panel-collapse' );

        // Toggle the panels when the associated tab is toggled
        tabs.on( 'shown.bs.tab', function ( e ) {

            if (fakewaffle.currentPosition === 'tabs') {
                var $current  = $( e.currentTarget.hash.replace( /#/, '#collapse-' ) );
                $current.collapse( 'show' );

                if ( e.relatedTarget ) {
                    var $previous = $( e.relatedTarget.hash.replace( /#/, '#collapse-' ) );
                    $previous.collapse( 'hide' );
                }
            }

        } );

        // Toggle the tab when the associated panel is toggled
        collapse.on( 'shown.bs.collapse', function ( e ) {

            if (fakewaffle.currentPosition === 'panel') {
                // Activate current tabs
                var current = $( e.target ).context.id.replace( /collapse-/g, '#' );
                $( 'a[href="' + current + '"]' ).tab( 'show' );

                // Update the content with active
                var panelGroup = $( e.currentTarget ).closest( '.panel-group.responsive' );
                $( panelGroup ).find( '.panel-body' ).removeClass( 'active' );
                $( e.currentTarget ).find( '.panel-body' ).addClass( 'active' );
            }

        } );
    };

    $( window ).resize( function () {
        fakewaffle.checkResize();
    } );

    return fakewaffle;
}( window.jQuery, fakewaffle || { } ) );
